// ** Initial State
const initialState = {
  allData: [],
  allbuildings: [],
  data: [],
  total: 1,
  params: {},
  currentUser: null,
  selectedUser: null,
  selectedBuilding: null,
  bookEdit: false,
  token: JSON.parse(localStorage.getItem("accessToken")),
  customPagination: null,
  found_results: "",
  entries_per_page: "",
  total_results: "",
};

// console.log('sssssssssssss', selectedUser)

const buildings = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_BUILDINGS":
      // return { ...state, allbuildings: [...action.data] }
      return {
        ...state,
        allbuildings: [...action.data],
        customPagination: action.customTotal,
        entries_per_page: action.entries_per_page,
        found_results: action?.found_results,
        total_results: action?.total_results,
      };

    case "DELETE_BUILDING":
      // console.log(action.data)
      return { ...state, allbuildings: action.data };
    case "EDITBUILDING":
      return { ...state, selectedBuilding: action.data };
    default:
      return { ...state };
  }
};
export default buildings;
