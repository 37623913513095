// ** Initial State
const initialState = {
  allData: [],
  allTransactionLogs: [],
  data: [],
  total: 1,
  params: {},
  customPagination: null,
  token: JSON.parse(localStorage.getItem("accessToken")),
  found_results: '',
  entries_per_page: '',
  total_results: ''

}

// console.log('sssssssssssss', selectedUser)

const transactionLog = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_TRANSACTIONLOGS':
      return { ...state, allTransactionLogs: action.data, customPagination: action.customTotal, entries_per_page: action.entries_per_page, found_results: action?.found_results, total_results: action?.total_results }
    case 'DELETE_TRANSACTIONLOG':
      // console.log(action.data)
      return { ...state, allTransactionLogs: action.data }
    case 'CLEAR_USERLOGS':
      return { ...state, allTransactionLogs: [] }
    
    default:
      return { ...state }
  }
}
export default transactionLog
