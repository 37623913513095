// ** Initial State
const initialState = {
  allZones: [],
  data: [],
  total: 1,
  params: {},
  selectedZone: null,
  token: JSON.parse(localStorage.getItem("accessToken")),
  customPagination: null,
  found_results: "",
  entries_per_page: "",
  total_results: "",
};

// console.log('sssssssssssss', selectedUser)

const zones = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_ZONES":
      // return { ...state, allZones: [...action.data] }
      return {
        ...state,
        allZones: [...action.data],
        customPagination: action.customTotal,
        entries_per_page: action.entries_per_page,
        found_results: action?.found_results,
        total_results: action?.total_results,
      };

    case "DELETE_ZONE":
      // console.log(action.data)
      return { ...state, allZones: action.data };
    case "EDITZONE":
      return { ...state, selectedZone: action.data };
    default:
      return { ...state };
  }
};
export default zones;
