// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import auth from "./auth";
import navbar from "./navbar";
import layout from "./layout";
import chat from "@src/views/apps/chat/store/reducer";
import todo from "@src/views/apps/todo/store/reducer";
import users from "@src/views/apps/usersRoute/user/store/reducer";
import admins from "../../views/apps/adminRoute/user/store/reducer";
import email from "@src/views/apps/email/store/reducer";
import invoice from "@src/views/apps/invoice/store/reducer";
import calendar from "@src/views/apps/calendar/store/reducer";
import ecommerce from "@src/views/apps/ecommerce/store/reducer";
import dataTables from "@src/views/tables/data-tables/store/reducer";
import roles from "@src/views/apps/roleSetting/user/store/reducer";
import notifications from "../../views/apps/notificationSetting/user/store/reducer";
import userLog from "../../views/apps/userLog/user/store/reducer";
import auditLog from "../../views/apps/auditLog/user/store/reducer";
import socket from "../../views/apps/socket.io/store/reducer";
import invoiceHistory from "../../views/apps/invoiceHistory/main/store/reducer";
import contracts from "../../views/apps/contractSetting/user/store/reducer";
import areaTypes from "../../views/apps/areaType/user/store/reducer";
import branchSetting from "../../views/apps/branchSetting/branchSetting/store/reducer";
import buildings from "../../views/apps/building/building/store/reducer";
import floors from "../../views/apps/floor/folder/store/reducer";
import zones from "../../views/apps/zone/folder/store/reducer";
import shops from "../../views/apps/propertyList/folder/store/reducer";
import announcements from "../../views/apps/announcement/folder/store/reducer";
import utility from "../../views/apps/utility/folder/store/reducer";
import tenants from "../../views/apps/tenant/folder/store/reducer";
import maintenances from '../../views/apps/maintenance/folder/store/reducer'
import subtenants from "../../views/apps/subTenant/folder/store/reducer";
import notis from "../../views/apps/notifications/folder/store/reducer";
import typeOfIssue from "../../views/apps/typeOfIssue/folder/store/reducer";
import invoiceBuldUpload from "../../views/apps/invoiceBulkUpload/folder/store/reducer";
import myInvoice from "../../views/apps/myInvoice/folder/store/reducer";
import transactionLog from "../../views/apps/transactionLogs/folder/store/reducer";

const rootReducer = combineReducers({
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  admins,
  roles,
  notifications,
  userLog,
  auditLog,
  socket,
  invoiceHistory,
  contracts,
  areaTypes,
  branchSetting,
  buildings,
  floors,
  zones,
  shops,
  announcements,
  utility,
  tenants,
  maintenances,
  subtenants,
  notis,
  typeOfIssue,
  invoiceBuldUpload,
  myInvoice,
  transactionLog
});

export default rootReducer;
