// ** Initial State
const initialState = {
  allFloors: [],
  data: [],
  total: 1,
  params: {},
  selectedFloor: null,
  token: JSON.parse(localStorage.getItem("accessToken")),
  customPagination: null,
  found_results: "",
  entries_per_page: "",
  total_results: "",
};

// console.log('sssssssssssss', selectedUser)

const floors = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_FLOORS":
      // return { ...state, allFloors: [...action.data] }
      return {
        ...state,
        allFloors: [...action.data],
        customPagination: action.customTotal,
        entries_per_page: action.entries_per_page,
        found_results: action?.found_results,
        total_results: action?.total_results,
      };

    case "DELETE_FLOOR":
      // console.log(action.data)
      return { ...state, allFloors: action.data };
    case "EDITFLOOR":
      return { ...state, selectedFloor: action.data };
    default:
      return { ...state };
  }
};
export default floors;
