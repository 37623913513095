import { store } from "@store/storeConfig/store";
// ** Initial State
const initialState = {
  allInvoices: [],
  allInvoiceTransactions: [],
  invoiceTotal: {},
  total: 1,
  params: {},
  customTotal: null,
  token: JSON.parse(localStorage.getItem("accessToken")),
  getError: false,
  errorMsg: "Error occured!!",
  otpSuccess: false,
  setNewPassword: false,
  found_results: "",
  entries_per_page: "",
  total_results: "",
  fetching: false,
  updatePagination: 0,
  isTokenExpire: false,
};
// console.log(store)

// console.log('sssssssssssss', selectedUser)

const myInvoice = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_INVOICES":
      return {
        ...state,
        allInvoices: action.data,
        invoiceTotal: action.invoiceTotal,
        customTotal: action.total,
        entries_per_page: action.entries_per_page,
        found_results: action?.found_results,
        total_results: action?.total_results,
      };
    case "GET_ALL_INVOICES_TRANSACTION":
      return {
        ...state,
        allInvoiceTransactions: action.data
      }


    case "DELETE_INVOICE":
      // console.log(action.data)
      return { ...state, allInvoices: action.data };
    case "DELETE_INVOICE_TRANSACTION":
      return {...state, allInvoiceTransactions: action.data}

    case "GET_ERROR":
      return { ...state, getError: true, errorMsg: action.error };
    default:
      return { ...state };
  }
};
export default myInvoice;
