import { store } from "@store/storeConfig/store";
// ** Initial State
const initialState = {
  allIssues: [],
  specificIssue: {},
  total: 1,
  params: {},
  customTotal: null,
  token: JSON.parse(localStorage.getItem("accessToken")),
  getError: false,
  errorMsg: "Error occured!!",
  otpSuccess: false,
  setNewPassword: false,
  found_results: "",
  entries_per_page: "",
  total_results: "",
  fetching: false,
  updatePagination: 0,
  isTokenExpire: false,
};
// console.log(store)

// console.log('sssssssssssss', selectedUser)

const typeOfIssue = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_TYPEOFISSUES":
      return {
        ...state,
        allIssues: action.data,
        customTotal: action.total,
        entries_per_page: action.entries_per_page,
        found_results: action?.found_results,
        total_results: action?.total_results,
      };

    case 'GET-SPECIFIC-TYPEOFISSUE':
      return {
        ...state, specificIssue: action.data
      }

    case "DELETE_TYPEOFISSUE":
      // console.log(action.data)
      return { ...state, allIssues: action.data };

    case "GET_ERROR":
      return { ...state, getError: true, errorMsg: action.error };
    default:
      return { ...state };
  }
};
export default typeOfIssue;
